import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceService } from '../service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  loginForm: FormGroup;

  public shares: Observable<any[]>;
  submitted: boolean = false;
  failed:boolean = false;


  constructor(private service: ServiceService,private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
    this.shares = this.getShares('/shares');
  }

  initForm(): void {
    this.loginForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,
      Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
      msg: ['', Validators.required],
    });
  }
  
  getShares(path) {
    return this.service.getShares(path);
  }

  addShare(name, email, msg) {
    const dataObj = {
      name: name,
      email: email,
      msg: msg
    };
    if(name=="" || email=="" || msg==""){
      this.failed = true;
    }
    else{
      this.service.addShare(dataObj);      
      this.submitted = true;
    }
  }

  onCloseSuccess() {
    //this.submitted = false;
  }

  onCloseFailed() {
    this.failed = false;
  }

  isValidInput(fieldName): boolean {
    return this.loginForm.controls[fieldName].invalid &&
      (this.loginForm.controls[fieldName].dirty || this.loginForm.controls[fieldName].touched);
  }



}
