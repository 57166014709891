import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  onHome(){
    this.router.navigate(['/home']);
  }
  onAbout(){
    this.router.navigate(['/about-us']);
  }
  onContact(){
    this.router.navigate(['/contact-us']);
  }
  onSpects(){
    this.router.navigate(['/spects']);
  }
  onLenses(){
    this.router.navigate(['/lenses']);
  }
  onSun(){
    this.router.navigate(['/sunglasses']);
  }
}
