import { Component, OnInit, SecurityContext } from '@angular/core';
import * as data from '../class/list-of-eyewear.json';
import { Eyewear } from '../class/eyewear';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.css']
})
export class ProductsPageComponent implements OnInit {

  listOfEyewear: [Eyewear] = (data as any).default;
  products: [Eyewear] = [{
    imageLink: "./../assets/images/Spects/Globus 1.jpg",
    category: "Spectacles",
    frameShape: "string",
    frameType: "string"
  }];

  //iframe: string = "";
  //sunglasses: [Eyewear] = [];
  //spectacles: [Eyewear] = [];


  constructor() {

  }

  ngOnInit() {
    /* console.log(this.listOfEyewear);
    for (var item of this.listOfEyewear) {
      if (item.category == "Sunglass") {
        //this.sunglasses.push(item);
      }
      else if (item.category == "Spectacles") {
        this.spectacles.push(item);
      }
    }
    this.onSunglasses(); */

  }

  /* onSunglasses() {
    console.log(this.sunglasses);
    this.products = this.sunglasses;
  }
  onSpectacles() {
    this.products = this.spectacles;
  } */

}
