import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sunglasses',
  templateUrl: './sunglasses.component.html',
  styleUrls: ['./sunglasses.component.css']
})
export class SunglassesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
