// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  firebaseConfig : {
    apiKey: "AIzaSyBFZVs3x2DzstTzg4eRQJQqelfoexoRwBc",
    authDomain: "opus-care.firebaseapp.com",
    databaseURL: "https://opus-care.firebaseio.com",
    projectId: "opus-care",
    storageBucket: "opus-care.appspot.com",
    messagingSenderId: "46489835496",
    appId: "1:46489835496:web:3f252e4fc3d48a7f61b65d",
    measurementId: "G-1QEZYTPRY8"

  }


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
