import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductsPageComponent } from './products-page/products-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LensesComponent } from './products-page/lenses/lenses.component';
import { SpectsComponent } from './spects/spects.component';
import { SunglassesComponent } from './sunglasses/sunglasses.component';


const routes: Routes = [
  {path : "home", component: HomePageComponent},  
  {path : "products", component: ProductsPageComponent},
  {path : "about-us", component: AboutUsComponent},
  {path : "contact-us", component: ContactUsComponent},
  {path : "lenses", component: LensesComponent},
  {path : "spects", component: SpectsComponent},
  {path : "sunglasses", component: SunglassesComponent},
  {path : '' , redirectTo: 'home', pathMatch: 'full'},
  {path : '**' , redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
