
import { Component, ViewChild, Input } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';


@Component({selector: 'ngbd-carousel-pause', templateUrl: './carousel.component.html'})
export class CarouselComponent {
 
 @Input('category') category:string;
  images:any;
  frames =  [{id:1, imageUrl: "../../assets/images/Sunglasses/Nova 4.png",name:"frame1" },
              {id:2, imageUrl:"../../assets/images/Sunglasses/Nova 5.png" ,name:"frame2"},
              {id: 3, imageUrl: "../../assets/images/Sunglasses/2.jpg", name: "frame3"},
              {id: 4, imageUrl:"../../assets/images/Sunglasses/Rosvin bugs 2.jpg" , name:"frame4"}
            ];

  lens =    [{id:1, imageUrl: "../../assets/images/Lens/l1.jpg",name:"lens1" },
                {id:2, imageUrl:"../../assets/images/Lens/l2.jpg" ,name:"lens2"},
                {id: 3, imageUrl: "../../assets/images/Lens/l3.jpg", name: "lens3"},
                //{id: 4, imageUrl:"../../assets/Images/go.jpg" , name:"lens4"}
              ];

  eyewear =        [{id:1, imageUrl: "../../assets/images/Spects/Nova 1.jpg",name:"eye1" },
                {id:2, imageUrl:"../../assets/images/Spects/Nova 2.jpg" ,name:"eye2"},
                {id: 3, imageUrl: "../../assets/images/Spects/Nova 3.png", name: "eye3"},
                {id: 4, imageUrl:"../../assets/images/Spects/Nova 6.png" , name:"eye4"}];
                
                reading =        [{id:1, imageUrl: "../../assets/images/book-3089857_1920.jpg",name:"eye1" },
                {id:2, imageUrl:"../../assets/images/go.jpg" ,name:"eye2"},
                {id: 3, imageUrl: "../../assets/images/glasses-3965545_1920.jpg", name: "eye3"},
                {id: 4, imageUrl:"../../assets/images/spectacles.png" , name:"eye4"}];
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  ngOnInit() {
    if(this.category === 'frames')
      this.images = this.frames;
    else if (this.category === 'eyewear')
      this.images = this.eyewear;
    else if(this.category=== 'lens')
      this.images = this.lens;
      else 
      this.images = this.reading;
   
  }

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
}