import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductsPageComponent } from './products-page/products-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { BarComponent } from './bar/bar.component';
import { CarouselComponent } from './carousel/carousel.component';

import { AngularFireModule } from '@angular/fire';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';

import {ServiceService} from './service.service';
import { LensesComponent } from './products-page/lenses/lenses.component';
import { SpectsComponent } from './spects/spects.component';
import { SunglassesComponent } from './sunglasses/sunglasses.component';
import { RegformComponent } from './regform/regform.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ProductsPageComponent,
    ContactUsComponent,
    AboutUsComponent,
    NavbarComponent,
    FooterComponent,
    BarComponent,
    CarouselComponent,
    LensesComponent,
    SpectsComponent,
    SunglassesComponent,
    RegformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebaseConfig)
  ],
  providers: [ServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
